import React, { useState, useEffect } from "react";
import { MDBCol, MDBRow } from 'mdbreact';
import {
    BarChart,
    ArticleDetailsModal,
    Card,
    PieChart,
} from "pubtrack-frontend-library";
import { itemsPerPage } from "../../constants";
import { getMessagesPerMonth, getMessagesTypes, getMessages, getMessageDetails } from "../../helpers/messages";
import { userSettings } from "../../user-settings";
import { transformData } from "../../helpers/helpers";
import MessagesTable from './MessagesTable'

const Messages = () => {
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [messageDetails, setMessageDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [messagesPerMonth, setMessagesPerMonth] = useState([]);
    const [messagesTypes, setMessagesTypes] = useState([]);

    useEffect(() => {
        getMessagesPerMonth()
            .then(res => setMessagesPerMonth(res.data));

        getMessagesTypes()
            .then(res => setMessagesTypes(res.data));
    }, []);

    useEffect(() => {
        if (selectedMessage) {
            getMessageDetails(selectedMessage)
                .then(res => setMessageDetails(transformData(res.data[0])))
                .then(setIsOpen(true))
                .then(setSelectedMessage(null))
        }
    }, [selectedMessage]);

    const onSelectRow = row => {
        const messageId = row && row.original ? row.original["id"] : null;
        setSelectedMessage(messageId);
    };

    const getMessagesData = ({ searchValue, offset, itemsPerPage, sortBy, sortDir, filterValue, ror }) => {
        return getMessages(searchValue, offset, itemsPerPage, sortBy, sortDir, filterValue, ror)
            .then(res => ({ data: res.data.messages, total: res.data.total }))
    };

    const getFilterData = () => {
        return Promise.resolve([
            {
                id: 'all_',
                issn: '', //TODO: FilterPanel component refactor needed (takes 'issn' or 'title' prop as value)
                title: 'All messages'
            },
            {
                id: 'e1',
                title: 'e1'
            },
            {
                id: 'e2',
                title: 'e2'
            },
            {
                id: 'p1',
                title: 'p1'
            },
            {
                id: 'p2',
                title: 'p2'
            }
        ]);
    };

    return (
        <div className="container">
            <MDBRow className="mt-5">
                <MDBCol md='8' className="mb-5 mt-4">
                    <Card
                        title="Messages per month:"
                        content={
                            <BarChart
                                data={messagesPerMonth}
                                xAxisKey="month"
                                yAxisKey="count"
                            />}
                    />
                </MDBCol>
                <MDBCol md='4' className="mb-5 mt-4">
                    <Card
                        title="Messages types:"
                        content={
                            <PieChart
                                data={messagesTypes}
                                labelKey="type"
                                valueKey="count"
                            />}
                    />
                </MDBCol>
            </MDBRow>
            <div className="mb-5">
                <MessagesTable
                    itemsPerPage={itemsPerPage}
                    getTableData={getMessagesData}
                    getFilterData={getFilterData}
                    onSelectRow={onSelectRow}
                />
            </div>
            <ArticleDetailsModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                articleDetails={messageDetails}
                tabItems={userSettings.messagesDetails}
            />
        </div>
    );
};


export default Messages;