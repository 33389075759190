// Here we can turn on/off items which are displayed at navbar and modal tabs by changing 'display' key

export const userSettings = {
    navbar: [
        {
            display: false,
            label: "In pipeline",
            path: "/pipeline"
        },
        {
            display: true,
            label: "Published articles",
            path: "/published"
        },
        {
            display: true,
            label: "Institutions",
            path: "/institutions"
        },
        {
            display: true,
            label: 'Funders',
            path: '/funders'
        },
        {
            display: true,
            label: "Messages",
            path: "/messages"
        },
        {
            display: false,
            label: "Workflow",
            path: "/workflow"
        },
    ],
    details: [
        {
            display: true,
            label: "Metadata",
        },
        {
            display: true,
            label: "JSON",
        },
        {
            display: true,
            label: "Events",
        },
        {
            display: true,
            label: "Messages",
        },
        {
            display: false,
            label: "Affiliations",
        },
    ],
    messagesDetails: [
        {
            display: false,
            label: "Metadata",
        },
        {
            display: true,
            label: "JSON",
        },
    ],
};