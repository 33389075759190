import React, { useState, useEffect } from "react";
import { MDBBox, MDBCol, MDBRow } from "mdbreact";
import { Field, withFormik } from "formik";
import { Table, Loader, Pagination, ErrorMessage, FilterPanel, AsyncInput, loadRorOptions } from "pubtrack-frontend-library";
import { messagesColumns } from "./columns";

const MessagesTable = ({
    getTableData,
    getFilterData,
    onSelectRow,
    itemsPerPage = 10,
    title,
    sortByDefault = "id",
    sortDirDefault = "desc",
}) => {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [sortBy, setSortBy] = useState(sortByDefault);
    const [sortDir, setSortDir] = useState(sortDirDefault);
    const [filterOptions, setFilterOptions] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [error, setError] = useState(false);

    const [rorSearchValue, setRorSearchValue] = useState("");

    useEffect(() => {
        if (typeof getFilterData === "function") {
            getFilterData()
                .then((data) => setFilterOptions(data))
                .catch(() => setError(true))
        }
    }, []);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                setLoading(true);
                const { data, total } = await getTableData({
                    searchValue: "",
                    offset,
                    itemsPerPage,
                    sortBy,
                    sortDir,
                    filterValue,
                    ror: rorSearchValue,
                });

                setTableData(data || []);
                setPageCount(Math.ceil(total / itemsPerPage));
                setLoading(false);

            } catch (error) {
                setError(true)
            } finally {
                setLoading(false)
            }
        }

        fetchMessages();
    }, [offset, sortBy, sortDir, filterValue, rorSearchValue]);

    const handlePageClick = (data) => {
        const selectedPage = data.selected;
        const offset = selectedPage * itemsPerPage;
        setCurrentPage(selectedPage);
        setOffset(offset + 1);
    };
    return (
        <>
            {title && <h5 className="text-center my-4">{title}</h5>}
            <MDBRow className="flex-wrap">
                <MDBCol sm="12" md="12" lg="4" className="d-flex align-items-center">
                    {getFilterData && (
                        <MDBBox my={3}>
                            <FilterPanel
                                title=""
                                selectOptions={filterOptions}
                                setFilterValue={setFilterValue}
                            />
                        </MDBBox>
                    )}
                </MDBCol>
                <MDBCol sm="12" md="12" lg="5" className="d-flex align-items-center">
                    <Field
                        component={AsyncInput}
                        loadOptions={loadRorOptions}
                        setValue={setRorSearchValue}
                        placeholder="All institutions"
                    />
                </MDBCol>
            </MDBRow>

            {loading ? (
                <Loader />
            ) : error ? (
                <ErrorMessage />
            ) : (
                <>
                    <Table
                        data={tableData}
                        columns={messagesColumns}
                        sortBy={sortBy}
                        sortDir={sortDir}
                        onSelectRow={onSelectRow}
                        setSortBy={setSortBy}
                        setSortDir={setSortDir}
                        exportAvailable={false}
                    />
                    <MDBRow>
                        <MDBCol sm="12" md="12" lg="4" className="d-flex align-items-center justify-content-start">
                            {itemsPerPage && (
                                <MDBBox my={3}>
                                    <Pagination
                                        handlePageClick={handlePageClick}
                                        pageCount={pageCount}
                                        visible={tableData.length}
                                        currentPage={currentPage}
                                    />
                                </MDBBox>
                            )}
                        </MDBCol>
                    </MDBRow>
                </>
            )}
        </>
    )
}

export default withFormik({})(MessagesTable);