export const changeFormatDate = date => {
    return date
        ? [date.getFullYear(), date.getMonth() + 1, date.getDate()].map((date) => (date < 10 ? `0${date}` : date)).join('-')
        : '';
};

export const changeFormatTime = date => {
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    const hours = date.getHours() >= 12 ? date.getHours() - 12 : date.getHours();
    const minutes = ('0' + date.getMinutes()).slice(-2);
    return `${hours}:${minutes} ${ampm}`;
};

export const transformDate = date => {
    let res = null;
    if (date) {
        const created = new Date(date);
        res = `${changeFormatDate(created)}`;
    }
    return res;
};

export const transformData = data => {
    return { article: { ...data } };
};