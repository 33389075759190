import axios from 'axios';
import { URL } from '../constants';
import { token as TOKEN } from "pubtrack-frontend-library";


const axiosInstance = axios.create({
    baseURL: URL
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN);
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = 'application/json';
    return config;
});


export { axiosInstance };