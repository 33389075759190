import React from "react";
import Routes from "./routes";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyles, Footer } from "pubtrack-frontend-library";

const customTheme = {
  ...theme,

  primaryColor: '#6ba439',
  secondaryColor: '#fff',

  accordionHeaderBackgroundColor: '#6ba439',

  modalHeaderBackgroundColor: '#6ba439',

  tabItemColor: '#fff',

  navbarBackgroundColor: '#fff',
  navbarButtonTextColor: '#212529',
  navbarButtonHoverColor: '#fff',
  navbarLogoBackgroundColor: '#06101a',
  navbarButtonHoverBorder: '2px solid #6ba439',
  navbarButtonPaddingBottom: '6px',
  logoutButtonBackgroundColor: '#6ba439',
  navbarButtonHoverTextColor: "#212529",
  navbarButtonActiveTextColor: "#212529",

  paginationButtonBackgroundColor: '#6ba439',
  paginationButtonHoverBackgroundColor: '#0f2834',

  tableHeaderBackgroundColor: '#6ba439',

  searchButtonBackgroundColor: '#6ba439',

  defaultButtonBackgroundColor: '#6ba439',
  primaryButtonBackgroundColor: '#6ba439',
  secondaryButtonBackgroundColor: '#6ba439',
  colorsPalette: [
    "#dcedc8", "#c5e1a5", "#aed581", "#9ccc65", "#8bc34a", "#7cb342", "#689f38", "#558b2f", "#33691e"
  ]
}

const App = () => (
  <ThemeProvider theme={customTheme}>
    <GlobalStyles />
    <Routes />
    <Footer />
  </ThemeProvider>
);

export default App;